import Button from '@rio-cloud/rio-uikit/Button';
import { Timer } from './vehicleChargingConfigurationTypes';

interface TimerChargingProps {
    assetId: string;
    timers?: Timer[];
    isReadOnly: boolean;
}

const TimerCharging = ({ assetId, timers, isReadOnly }: TimerChargingProps) => {
    if (timers === undefined || timers.length === 0) {
        return (
            <div>
                <div className="display-flex">
                    <span className="rioglyph rioglyph-time text-color-light text-size-h1" />
                    <div className="display-flex flex-column margin-left-20">
                        <span className="text-bold">{"You don't have any timers yet."}</span>
                        {!isReadOnly && (
                            <span className="text-color-gray">{'To create one, simply click on "Add new Timer"'}</span>
                        )}
                    </div>
                </div>
                {!isReadOnly && (
                    <>
                        <br />
                        <div>
                            <Button
                                bsStyle={Button.PRIMARY}
                                iconName="rioglyph-plus"
                                className="width-100pct justify-content-start"
                                onClick={() => {}}
                            >
                                Add new timer
                            </Button>
                        </div>
                    </>
                )}
            </div>
        );
    }
    return (
        <div>
            <span>{assetId}</span>
            <span>{isReadOnly}</span>
        </div>
    );
};

export default TimerCharging;
