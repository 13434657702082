import { VehicleChargingConfiguration } from './vehicleChargingConfigurationTypes';
import { FormattedMessage } from 'react-intl';
import ImmediateCharging from './ImmediateCharging';
import TimerCharging from './TimerCharging';

export const createChargingTabs = (
    assetId: string,
    vehicleDetails: VehicleChargingConfiguration | null,
    isReadOnly: boolean
) => [
    {
        id: 'immediateTab',
        label: <FormattedMessage id="e4c.vehicle.details.tab.immediateCharging" />,
        content: (
            <ImmediateCharging
                assetId={assetId}
                configuration={vehicleDetails?.immediateConfiguration}
                isReadOnly={isReadOnly}
            />
        ),
    },
    {
        id: 'timerTab',
        label: <FormattedMessage id="e4c.vehicle.details.tab.timerCharging" />,
        content: <TimerCharging assetId={assetId} timers={vehicleDetails?.timers} isReadOnly={isReadOnly} />,
    },
];
