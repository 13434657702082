import { PropsWithChildren, useState } from 'react';
import { VehicleDetailsContext } from './contexts';

const VehicleDetailsProvider = ({ children }: PropsWithChildren) => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [sendingChargingConfigFailed, setSendingChargingConfigFailed] = useState(false);
    const [sendingChargingConfigSucceeded, setSendingChargingConfigSucceeded] = useState(false);
    const [changedChargingTab, setChangedChargingTab] = useState(false);

    const resetContext = () => {
        setHasUnsavedChanges(false);
        setSendingChargingConfigFailed(false);
        setSendingChargingConfigSucceeded(false);
        setChangedChargingTab(false);
    };

    return (
        <VehicleDetailsContext.Provider
            value={{
                hasUnsavedChanges,
                setHasUnsavedChanges,
                sendingChargingConfigFailed,
                setSendingChargingConfigFailed,
                sendingChargingConfigSucceeded,
                setSendingChargingConfigSucceeded,
                changedChargingTab,
                setChangedChargingTab,
                resetContext,
            }}
        >
            {children}
        </VehicleDetailsContext.Provider>
    );
};

export default VehicleDetailsProvider;
