import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import Configuration from './Configuration';
import { ImmediateConfiguration } from './vehicleChargingConfigurationTypes';
import { VehicleDetailsContext } from '../../../../../../../providers/contexts';
import { usePostImmediateConfigurations } from '../../../../queries/useVehicleChargingAPI';
import { State } from '../../../../../../../types';
import { getAccessToken } from '../../../../../../../configuration';

interface ImmediateChargingProps {
    assetId: string;
    configuration?: ImmediateConfiguration;
    isReadOnly: boolean;
}

const ImmediateCharging = ({ assetId, configuration, isReadOnly }: ImmediateChargingProps) => {
    const intl = useIntl();

    const initialTargetSoc = configuration?.targetSoc ?? 60;
    const initialClimateMode = configuration?.climateMode ?? false;
    const [targetSoc, setTargetSoc] = useState(initialTargetSoc);
    const [climateMode, setClimateMode] = useState(initialClimateMode);

    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { isError, isSuccess, mutate } = usePostImmediateConfigurations(accessToken, assetId, {
        targetSoc,
        climateMode,
    });

    const {
        hasUnsavedChanges,
        setHasUnsavedChanges,
        setSendingChargingConfigFailed,
        setSendingChargingConfigSucceeded,
        changedChargingTab,
    } = useContext(VehicleDetailsContext);

    // Set the unsaved changes flag when the configuration values are modified
    useEffect(() => {
        setHasUnsavedChanges(targetSoc !== initialTargetSoc || climateMode !== initialClimateMode);
    }, [targetSoc, climateMode, setHasUnsavedChanges, initialTargetSoc, initialClimateMode]);

    // Set the notifications according to the Save action result
    useEffect(() => {
        setSendingChargingConfigSucceeded(isSuccess);
        setSendingChargingConfigFailed(isError);

        if (isSuccess) {
            setHasUnsavedChanges(false);
        }
    }, [isSuccess, isError, setSendingChargingConfigSucceeded, setSendingChargingConfigFailed, setHasUnsavedChanges]);

    return (
        <>
            <Configuration
                title={intl.formatMessage({ id: 'e4c.vehicle.details.chargeUntil.title' })}
                description={intl.formatMessage({ id: 'e4c.vehicle.details.chargeUntil.description' })}
                slider={{
                    value: targetSoc,
                    minValue: 0,
                    maxValue: 100,
                    step: 1,
                    unit: '%',
                    setValue: setTargetSoc,
                }}
            />
            <hr />
            <Configuration
                title={intl.formatMessage({ id: 'e4c.vehicle.details.climateControl.title' })}
                description={intl.formatMessage({ id: 'e4c.vehicle.details.climateControl.description' })}
                toggle={{
                    value: climateMode,
                    setValue: setClimateMode,
                }}
                warningTag={{
                    tag: intl.formatMessage({ id: 'e4c.vehicle.details.notRecommended.tag' }),
                    tooltip: intl.formatMessage({ id: 'e4c.vehicle.details.notRecommended.tooltip' }),
                }}
            />
            <button
                className="btn btn-primary display-flex margin-left-auto margin-top-20"
                type="button"
                id="save-button"
                disabled={!(hasUnsavedChanges || changedChargingTab) || isReadOnly}
                onClick={() => mutate()}
            >
                <FormattedMessage id="e4c.global.save" />
            </button>
        </>
    );
};

export default ImmediateCharging;
