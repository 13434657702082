import { ChangeEvent } from 'react';
import { Slider } from '@rio-cloud/rio-uikit';
import Switch from '@rio-cloud/rio-uikit/Switch';
import { PLACEMENT } from '@rio-cloud/rio-uikit/values/Placement';
import { ConfigurationProps } from './types';
import TooltipRef from '../../../../../common/components/Tooltip/Tooltip';

const Configuration = ({ title, description, toggle, slider, warningTag }: ConfigurationProps) => {
    const handleSliderInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        // Allow only numbers, and remove leading zeros
        if (!/^\d*$/.test(inputValue)) {
            return;
        }
        let newValue = parseInt(inputValue, 10);

        if (isNaN(newValue)) {
            newValue = 0;
        } else if (newValue > 100) {
            newValue = 100;
        }
        slider?.setValue(newValue);
    };

    return (
        <>
            <div className="display-flex justify-content-between align-items-center">
                <div>
                    <div className="display-flex margin-bottom-5">
                        <div className="text-bold">{title}</div>
                        {warningTag && (
                            <div className="label label-warning label-condensed margin-left-10">
                                <span className="padding-right-5">{warningTag.tag}</span>
                                <TooltipRef
                                    tooltipContent={warningTag.tooltip}
                                    placement={PLACEMENT.BOTTOM_START}
                                    width={300}
                                    textAlignment="left"
                                    ellipsedChildren={false}
                                >
                                    <span className="rioglyph rioglyph-exclamation-sign" />
                                </TooltipRef>
                            </div>
                        )}
                    </div>
                    {description && <div className="text-color-dark">{description}</div>}
                </div>
                {toggle && <Switch onChange={newValue => toggle.setValue(newValue)} checked={toggle.value} />}
            </div>

            {slider && (
                <div className="display-flex align-items-center justify-content-between margin-top-20">
                    <Slider
                        value={slider.value}
                        minValue={slider.minValue}
                        maxValue={slider.maxValue}
                        step={slider.step}
                        valueLabels={true}
                        valueLabelUnit={slider.unit}
                        onChange={newValue => slider.setValue(newValue)}
                    />
                    <div className="input-group width-100 margin-left-25">
                        <input
                            className="form-control text-center no-controls"
                            type="text"
                            value={slider.value}
                            onChange={handleSliderInputChange}
                        />

                        <div className="input-group-addon">{slider.unit}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Configuration;
