import { useMutation, useQuery } from 'react-query';
import ServicesApi from '../../../../api/Api';
import mappingVehicleChargingFromServer from '../helpers/mappingVehicleChargingFromServer';
import { MonitoringServiceResponse } from '../models/api/monitoringServiceResponse';
import { StateServiceResponse } from '../models/api/stateServiceResponse';
import { VehicleChargingData } from '../models/VehicleChargingData';
import { AccessToken } from '../../../../configuration';
import { fetchVehicleChargingConfigurations, postImmediateConfigurations } from '../../../../api/vehicleCharging';
import { ImmediateConfigurationRequest, TimerResponse, VehicleChargingConfigurationResponse } from './types';
import {
    ImmediateConfiguration,
    Timer,
    VehicleChargingConfiguration,
} from '../components/details/vehicleDetails/ChargingMode/vehicleChargingConfigurationTypes';

const TEN_MINUTES = 300000 * 2;

const queryOptions = (accessToken?: string | null) => ({ refetchInterval: TEN_MINUTES, enabled: !!accessToken });

export const useVehicleChargingAPI = (
    refreshTableAfterSaveDetails: boolean,
    accessToken?: string | null
): { isLoading: boolean; isError: boolean; vehicleChargingData: VehicleChargingData[] } => {
    const monitor = useQuery<unknown, Error, MonitoringServiceResponse>(
        ['monitor-vc', refreshTableAfterSaveDetails],
        () => ServicesApi.getMonitoringVehicles(accessToken).then(res => res.json()),
        queryOptions(accessToken)
    );
    const state = useQuery<unknown, Error, StateServiceResponse>(
        ['state-vc', refreshTableAfterSaveDetails],
        () => ServicesApi.getStateVehicles(accessToken).then(res => res.json()),
        queryOptions(accessToken)
    );

    const isLoading = monitor.isLoading || state.isLoading;
    const isError = monitor.isError || state.isError;

    const vehicleChargingData: VehicleChargingData[] = mappingVehicleChargingFromServer(monitor.data, state.data);

    return { isLoading, isError, vehicleChargingData };
};

export const useGetVehicleChargingConfiguration = (
    accessToken: AccessToken,
    assetId: string
): { isLoading: boolean; isError: boolean; vehicleChargingConfiguration: VehicleChargingConfiguration | null } => {
    const { isLoading, isError, data } = useQuery([`vehicle-charging-${assetId}`], () =>
        fetchVehicleChargingConfigurations(accessToken, assetId).then(res => res.json())
    );
    const vehicleChargingConfiguration = data ? mapVehicleChargingConfiguration(data) : null;
    return { isLoading, isError, vehicleChargingConfiguration };
};

export const usePostImmediateConfigurations = (
    accessToken: AccessToken,
    assetId: string,
    configuration: ImmediateConfiguration
) => {
    const body = requestImmediateConfiguration(configuration);
    return useMutation(() => postImmediateConfigurations(accessToken, assetId, JSON.stringify(body)));
};

const mapTimersFromResponse = (timers: TimerResponse[]): Timer[] => {
    return timers.map(timer => {
        return {
            weekdays: timer.weekdays,
            departureTimeHour: timer.departure_time_hour,
            departureTimeMinute: timer.departure_time_minute,
            zoneId: timer.zone_id,
            climateMode: timer.climate_mode,
            chargeUntil: timer.charge_until,
            readyToDriveDuration: timer.ready_to_drive_duration,
            weeklyRepeat: timer.weekly_repeat,
            enabled: timer.enabled,
            nextDeparturesTimeStatus: timer.next_departures_time_status.map(nextDepartureTime => ({
                status: nextDepartureTime.status,
                departureTime: nextDepartureTime.departure_time,
            })),
        };
    });
};

const mapVehicleChargingConfiguration = (data: VehicleChargingConfigurationResponse): VehicleChargingConfiguration => {
    return {
        assetId: data.asset_id,
        chargingMode: data.charging_mode,
        immediateConfiguration: {
            climateMode: data.configurations.immediate.climate_mode,
            targetSoc: data.configurations.immediate.charge_until,
        },
        timers: mapTimersFromResponse(data.configurations.timer),
        userAction: {
            postImmediateConfigurations: data._actions?.post_immediate_configurations,
            postTimerConfigurations: data._actions?.post_timer_configurations,
        },
    };
};

const requestImmediateConfiguration = (configuration: ImmediateConfiguration): ImmediateConfigurationRequest => ({
    climate_mode: configuration.climateMode,
    target_state_of_charge: configuration.targetSoc,
});
