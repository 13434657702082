import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { PLACEMENT } from '@rio-cloud/rio-uikit/values/Placement';
import TooltipRef from '../../../../../common/components/Tooltip/Tooltip';
import { State } from '../../../../../../../types';
import { getAccessToken } from '../../../../../../../configuration';
import { useGetVehicleChargingConfiguration } from '../../../../queries/useVehicleChargingAPI';
import { VehicleDetailsContext } from '../../../../../../../providers/contexts';
import { ChargingModeEnum } from '../../../../types';
import ErrorState from '../../../../../common/components/states/ErrorState';
import UnsavedChangesDialog from './UnsavedChangesDialog';
import { createChargingTabs } from './createChargingTabs';
import { VehicleChargingConfiguration } from './vehicleChargingConfigurationTypes';

const ChargingMode = ({ assetId }: { assetId: string }) => {
    const intl = useIntl();
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { isLoading, isError, vehicleChargingConfiguration } = useGetVehicleChargingConfiguration(
        accessToken,
        assetId
    );
    const { hasUnsavedChanges, setHasUnsavedChanges, setChangedChargingTab } = useContext(VehicleDetailsContext);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const isReadOnly = isReadOnlyUser(vehicleChargingConfiguration);
    const chargingTabs = createChargingTabs(assetId, vehicleChargingConfiguration, isReadOnly);
    const chargingMode = vehicleChargingConfiguration?.chargingMode;
    const initialTab = chargingMode === ChargingModeEnum.TIMER ? chargingTabs[1].id : chargingTabs[0].id;
    const [activeTab, setActiveTab] = useState(initialTab);
    const [nextTabId, setNextTabId] = useState('');

    useEffect(() => {
        setActiveTab(initialTab);
    }, [initialTab]);

    const handleSwitchTabs = (tabId: string) => {
        if (hasUnsavedChanges) {
            setNextTabId(tabId);
            setShowConfirmDialog(true);
        } else {
            setActiveTab(tabId);
            setChangedChargingTab(tabId !== initialTab);
        }
    };

    const handleDialogConfirm = () => {
        if (nextTabId) {
            setActiveTab(nextTabId);
            setChangedChargingTab(nextTabId !== initialTab);
            setHasUnsavedChanges(false);
        }
        setShowConfirmDialog(false);
    };

    if (isLoading) {
        return <Spinner />;
    }
    if (isError || !vehicleChargingConfiguration) {
        return <ErrorState stateProps={{ type: 'general', withoutBorder: true }} />;
    }

    return (
        <>
            <div className="margin-20" data-testid="charging-mode">
                <div className="margin-bottom-20">
                    <span className="padding-right-5 text-bold">
                        <FormattedMessage id="e4c.vehicle.details.chargingMode.title" />
                    </span>
                    <TooltipRef
                        tooltipContent={intl.formatMessage({ id: 'e4c.vehicle.details.chargingMode.tooltip' })}
                        placement={PLACEMENT.BOTTOM_START}
                        width={400}
                        textAlignment="left"
                        ellipsedChildren={false}
                    >
                        <span className="rioglyph rioglyph-exclamation-sign" />
                    </TooltipRef>
                </div>
                <div>
                    <ul className="nav nav-tabs nav-justified nav-justified-word-wrap">
                        {chargingTabs.map(tab => (
                            <li key={tab.id} className={activeTab === tab.id ? 'active' : ''}>
                                <a onClick={() => handleSwitchTabs(tab.id)} data-testid={tab.id}>
                                    {tab.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                    {chargingTabs.map(
                        tab =>
                            activeTab === tab.id && (
                                <div key={tab.id} id={tab.id} className="tab-content-bordered">
                                    {tab.content}
                                </div>
                            )
                    )}
                </div>
            </div>
            <UnsavedChangesDialog
                showDialog={showConfirmDialog}
                onConfirm={handleDialogConfirm}
                onCancel={() => setShowConfirmDialog(false)}
            />
        </>
    );
};

const isReadOnlyUser = (vehicleDetailsData: VehicleChargingConfiguration | null) =>
    !(
        vehicleDetailsData &&
        vehicleDetailsData.userAction &&
        vehicleDetailsData.userAction.postTimerConfigurations &&
        vehicleDetailsData.userAction.postImmediateConfigurations
    );

export default ChargingMode;
