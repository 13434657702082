import { useContext, useEffect, useState } from 'react';
import { VehiclesProperties } from '../types';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage } from 'react-intl';
import VehicleDetailsContainer from '../containers/details/VehicleDetails.container';
import VehiclesBody from './table/VehiclesBody';
import { useHistory } from 'react-router';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';
import { useSelector } from 'react-redux';
import { State } from '../../../../types';
import { config } from '../../../../config';
import ErrorState from '../../common/components/states/ErrorState';
import { AssetTreeContext } from '../../../../providers/contexts';
import { useVehicleChargingAPI } from '../queries/useVehicleChargingAPI';
import NewVehicleDetailsSidebar from './details/vehicleDetails/NewVehicleDetailsSidebar';
import { useFeatureToggle } from '../../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../configuration/featureToggle/featureToggles';

type VehiclesProps = VehiclesProperties;

const Vehicles = ({ selectRow, selectedRow, vehiclesLoadingErrorCode, accessToken }: VehiclesProps): JSX.Element => {
    const [unsavedVehicleChanges, setUnsavedVehicleChanges] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const refreshTableAfterSaveDetails = useSelector(
        (state: State) => state.vehicleDetails.sendingChargingConfigSucceeded
    );
    const history = useHistory();
    const { selectedAssetIds: selectedVehicleIds } = useContext(AssetTreeContext);
    const { value: isRedesignChargingEnabled } = useFeatureToggle(FeatureToggles.REDESIGN_CHARGING_CONTEXT);

    const { isLoading, isError, vehicleChargingData } = useVehicleChargingAPI(
        refreshTableAfterSaveDetails,
        accessToken
    );

    useEffect(() => {
        const assetId = getVehicleIdFromUrl();
        if (assetId) {
            selectRow(assetId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (unsavedVehicleChanges) {
            setUnsavedVehicleChanges(true);
            setShowConfirmDialog(true);
        } else if (selectedRow) {
            updateUrl(selectedRow);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow]);

    const getVehicleIdFromUrl = (): string | null => {
        const params = history.location.pathname.split('/').filter(param => param !== 'vehicles' && param !== '');
        return params.length > 0 ? params[0] : null;
    };

    const vehicleSelected = vehicleChargingData?.find(a => a.assetId === selectedRow);

    const updateUrl = (pathParamValue: string): void => {
        const pathParam = pathParamValue ? `/${pathParamValue}` : '';
        history.push({ pathname: `/vehicles${pathParam}`, search: history.location.search });
    };

    const onSidebarChange = (): void => {
        setUnsavedVehicleChanges(false);
        setShowConfirmDialog(false);
        selectRow('');
        updateUrl('');
    };

    const applicationBodyBanner = config.banner.enable && (
        <ApplicationLayoutBodyBanner>{config.banner.message}</ApplicationLayoutBodyBanner>
    );

    return (
        <>
            {vehiclesLoadingErrorCode || isError ? (
                <ApplicationLayout.Body className={'responsive'} banner={applicationBodyBanner}>
                    <ErrorState
                        stateProps={{
                            type: vehiclesLoadingErrorCode === ErrorCodeEnum.FORBIDDEN ? 'forbidden' : 'general',
                        }}
                    />
                </ApplicationLayout.Body>
            ) : (
                <>
                    <ConfirmationDialog
                        show={showConfirmDialog}
                        title={<FormattedMessage id={'e4c.vehicle.details.unsavedChangesConfirmationDialog.title'} />}
                        content={<FormattedMessage id="e4c.vehicles.details.unsavedChangesText" />}
                        onClickConfirm={onSidebarChange}
                        onClickCancel={(): void => setShowConfirmDialog(false)}
                        cancelButtonText={<FormattedMessage id="e4c.global.keepEditing" />}
                        confirmButtonText={<FormattedMessage id="e4c.global.discardChanges" />}
                        disableConfirm={false}
                        useOverflow={false}
                        bsSize={'sm'}
                    />
                    <ApplicationLayout.Sidebar className="right">
                        {selectedRow &&
                            vehicleSelected &&
                            (isRedesignChargingEnabled ? (
                                <NewVehicleDetailsSidebar
                                    vehicle={vehicleSelected}
                                    onCloseSidebar={onSidebarChange}
                                    onToggleConfirmDialog={setShowConfirmDialog}
                                    onToggleUnsavedVehicleChanges={setUnsavedVehicleChanges}
                                />
                            ) : (
                                <VehicleDetailsContainer
                                    onClose={onSidebarChange}
                                    toggleConfirmDialog={setShowConfirmDialog}
                                    toggleUnsavedVehicleChanges={setUnsavedVehicleChanges}
                                    vehicleObjectFromTable={vehicleSelected}
                                />
                            ))}
                    </ApplicationLayout.Sidebar>
                    <ApplicationLayout.Body className={'responsive'} banner={applicationBodyBanner}>
                        <VehiclesBody
                            selectedVehicleIds={selectedVehicleIds}
                            vehicleChargingData={vehicleChargingData}
                            isLoading={isLoading}
                        />
                    </ApplicationLayout.Body>
                </>
            )}
        </>
    );
};

export default Vehicles;
